.rightContainer {
  display: flex;
  gap: 1rem;
}

.footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 5rem;
  padding: 1rem 1.7rem;
  border-top: 0.125rem solid var(--Primary-Dark);
  box-sizing: border-box;

  font-weight: 400;
  font-size: 0.9rem;
  line-height: 20px;

  text-align: center;

  color: var(--Eggshell);
}

.termsLink {
  display: flex;
  align-items: center;
  padding: 0.7rem 1rem;
  border-radius: 39px;
  border: 1px solid var(--Eggshell, #fbf6ec);
  color: var(--Eggshell);
}

.corpInfo {
  color: rgba(251, 246, 236, 0.5);
  text-align: center;
  font-size: 0.875;
  font-style: normal;
  font-weight: 400;
}
