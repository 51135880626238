.actions {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 1.1rem;

  :global {
    .icon {
      cursor: pointer;
      font-size: 1.4rem;
      transition: 0.2s;
      opacity: 1;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
