@import '@/styles/screens.scss';

.main {
  width: 100%;
  height: 100%;

  overflow-y: auto;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: var(--Primary-Dark, #2e2e2e);

  &::-webkit-scrollbar {
    background-color: rgba(245, 239, 225, 0.9411764706);
  }

  @include media('s-screen') {
    background: #fbf6ec;
  }

  .container {
    flex: 1;
    background-color: var(--Eggshell, #2e2e2e);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 20px;
  }
}
